/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import axios from "axios";
import routes from "./routes";
import Cookies from "js-cookie";
import useSound from "use-sound";
import { TYPES } from "./redux/actionTypes";
import { useRoutes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { browserName } from "react-device-detect";
import { getAllFaq } from "./redux/actions/faqAction";
import { FILE_TYPES, MESSAGE_SOUND } from "./constant";
import { useDispatch, useSelector } from "react-redux";
import { getAllBots } from "./redux/actions/botAction";
import { userSession } from "./redux/actions/sessionAction";
import { connectSocket } from "./redux/actions/socketAction";
import { getAllWidgets } from "./redux/actions/widgetAction";
import { CallModal, ShareFileModal } from "./components/modal";
import { getAllResponse } from "./redux/actions/responseAction";
import { getApplication } from "./redux/actions/applicationAction";
import { getAllDepartments } from "./redux/actions/departmentAction";
import { getAllEngageEntry } from "./redux/actions/engageEntryAction";
import ConfirmationModal from "./components/others/confirmationModal";
import { addNewNotification } from "./redux/actions/notificationAction";
import { PushNotification, ToastContainer as Toaster } from "./components";
import {
  updateDeleteStatus,
  getAllConversations,
  applicationConversations,
} from "./redux/actions/chatAction";
import {
  AddNewGroup,
  getAllGroups,
  GetGroupById,
  EditGroupById,
  DeleteGroupById,
  AddUnreadMessage,
  UpdateGroupMessageByIndex,
  UpdateSpecificGroupMessage,
  UpdateDeletedMsgWithSocket,
} from "./redux/actions/groupChatAction";
import { getCallToken } from "./redux/actions/callAction";
import { ToastContainer } from "react-toastify";

import "./assets/fontawesome-pro-5.15.4-web/css/all.min.css";
import { getAllEmailTemplates } from "./redux/actions/emailTemplateAction";
if (!localStorage.getItem("theme")) {
  const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)");
  if (prefersDarkMode.matches) {
    localStorage.setItem("theme", "dark");
  } else if (!prefersDarkMode.matches) {
    localStorage.setItem("theme", "light");
  }
}
let Theme = localStorage.getItem("theme");

if (Theme === "light") {
  import("./assets/scss/template.scss");
} else if (Theme === "dark") {
  import("./assets/scss/template.dark.scss");
}

let playChatSound;
let playGroupSound;

const App = () => {
  const [callType, setCallType] = useState("");
  const [senderData, setSenderData] = useState({});
  const [userLocation, setUserLocation] = useState({});
  const [showCallModal, setShowCallModal] = useState(false);
  const [conversationId, setConversationId] = useState("");
  const [coordinates, setCoordinates] = useState({
    longitude: ``,
    latitude: ``,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [send_message_sound] = useSound(MESSAGE_SOUND.SENDING);

  const { userData, allUsers } = useSelector((state) => state.userReducer);
  const { videoSidebar } = useSelector((state) => state.callReducer);
  const { socket } = useSelector((state) => state.socketReducer);
  const { allGroups } = useSelector((state) => state.groupChatReducer);
  const { videoCallStatus, groupVideoCallStatus, callData } = useSelector(
    (state) => state.callReducer
  );

  useEffect(() => {
    if (
      sessionStorage.getItem("videoCallStartTime") &&
      videoCallStatus !== "connected" &&
      videoCallStatus !== "connecting"
    ) {
      sessionStorage.removeItem("videoCallStartTime");
    }
    if (
      sessionStorage.getItem("groupCallStartTime") &&
      groupVideoCallStatus !== "connected" &&
      groupVideoCallStatus !== "connecting"
    ) {
      sessionStorage.removeItem("groupCallStartTime");
    }
  }, [videoCallStatus, groupVideoCallStatus]);

  const { allOperators } = useSelector((state) => state.operatorReducer);
  const { allConversations, conversationById } = useSelector(
    (state) => state.chatReducer
  );
  const { appSettings } = useSelector((state) => state.applicationReducer);
  const { modal } = useSelector((state) => state.corfirmationModalReducer);

  const content = useRoutes(routes);

  let count1 = 0;
  let count2 = 0;

  // All Sockets //
  useEffect(() => {
    if (Object.keys(socket).length > 0) {
      socket.on(`addGroupMessageToClient`, (msg) => {
        if (msg?.data?._id) handleGroupMessageNotification(msg);
        if (msg?.type === "document" || msg?.type === "voice") {
          playGroupSound(msg?.groupId);
          dispatch(UpdateSpecificGroupMessage(msg?.data, msg?.groupId));
          if (msg?.data?.unread_messages) {
            dispatch(
              AddUnreadMessage({
                groupId: msg?.groupId,
                unread_messages: msg?.data?.unread_messages,
              })
            );
          }
          return;
        }
        if (msg?.data?._id) {
          dispatch(UpdateGroupMessageByIndex(msg?.data));
        } else {
          playGroupSound(msg?.groupId);
          dispatch(UpdateSpecificGroupMessage(msg?.data, msg?.groupId));
        }
        if (msg?.data?.unread_messages) {
          dispatch(
            AddUnreadMessage({
              groupId: msg?.groupId,
              unread_messages: msg?.data?.unread_messages,
            })
          );
        }
      });
      socket.on(`receiveUpdatedGroupData`, (groupData) => {
        dispatch(EditGroupById(groupData));
        dispatch(GetGroupById(groupData?.groupId));
      });
      socket.on(`createNewGroupToClient`, (group) => {
        dispatch(AddNewGroup(group));
      });
      socket.on(`deleteGroupToClient`, (group) => {
        dispatch(DeleteGroupById(group));
      });
      socket.on(`updateDeleteMessageToClient`, (msg) => {
        dispatch(UpdateDeletedMsgWithSocket(msg));
      });
      socket.on("addMessageToClient", (data) => {
        if (data?.is_push_notification) handleMessageNotification(data);
        dispatch({
          type: TYPES.PUSH_MESSAGE,
          payload: data,
        });
        playChatSound(data?.conversationId);
      });
      socket.on(`notificationResponse`, (data) => {
        dispatch(addNewNotification(data));
      });
      socket.on(`messageNotificationToClient`, (data) => {
        dispatch({
          type: TYPES.MESSAGE_NOTIFICATION,
          payload: data,
        });
      });
      socket.on(`requestRemovingChatToClient`, (data) => {
        dispatch({
          type: TYPES.DELETE_CONVERSATION,
          payload: {
            _id: data?.conversationId,
          },
        });
      });
      socket.on(`typingToClient`, (data) => {
        data.is_typing = true;
        count1++;
        dispatch({
          type: TYPES.IS_TYPING,
          payload: data,
        });
        setTimeout(() => {
          count2++;
          if (count1 === count2) {
            data.is_typing = false;
            dispatch({
              type: TYPES.IS_TYPING,
              payload: data,
            });
          }
        }, 1000);
      });
      socket.on("typingInGroupToClient", (data) => {
        data.is_typing = true;
        count1++;
        dispatch({
          type: TYPES.IS_TYPING_IN_GROUP,
          payload: data,
        });
        setTimeout(() => {
          count2++;
          if (count1 === count2) {
            data.is_typing = false;
            dispatch({
              type: TYPES.IS_TYPING_IN_GROUP,
              payload: data,
            });
          }
        }, 1000);
      });
      socket.on(`updateConversationToClient`, (data) => {
        dispatch({
          type: TYPES.UPDATE_CONVERSATION_BY_ID,
          payload: data?.data,
        });
      });
      socket.on(`updateOneTooOneDeleteMsgToClient`, (msg) => {
        dispatch(updateDeleteStatus(msg));
      });
      socket.on(`onlineUsersToClient`, (data) => {
        dispatch({
          type: TYPES.GET_ONLINE_USER,
          payload: data,
        });
      });
      socket.on(`broadcastOnlineUsersToClient`, (data) => {
        dispatch({
          type: TYPES.GET_ONLINE_USER,
          payload: data,
        });
      });
      socket.on(`clientChatAccepted`, (data) => {
        dispatch({
          type: TYPES.UPDATE_ALL_NOTIFICATIONS,
          payload: {
            data: data,
          },
        });
      });
      socket.on("user_not_found", () => {
        Toaster({
          success: false,
          message: "User is not available",
        });
        dispatch({
          type: TYPES.VIDEO_CALL_STATUS,
          payload: null,
        });
      });
      socket.on(`callUser`, (data) => {
        setCallType(data?.callType);
        setShowCallModal(true);
        setSenderData(data?.senderData);
        dispatch({
          type: TYPES.VIDEO_CALL_SIGNALS,
          payload: data?.signalData,
        });
        dispatch({
          type: TYPES.VIDEO_CALL_STATUS,
          payload: "ringing",
        });
      });
      socket.on(`callDeclined`, (data) => {
        setShowCallModal(false);
        Toaster({
          success: false,
          message: "Call declined",
        });
        // dispatch({
        //   type: TYPES.VIDEO_CALL_STATUS,
        //   payload: null,
        // });
        // if (data?.navigate_back) {
        //   // window.history.back();
        // }
      });
      socket.on("callCanceled", (userById) => {
        setShowCallModal(false);
        setSenderData({});
        dispatch({
          type: TYPES.VIDEO_CALL_STATUS,
          payload: null,
        });
      });
      socket.on("callAnswered", (senderData) => {
        dispatch({
          type: TYPES.VIDEO_CALL_STATUS,
          payload: "connected",
        });
      });
      socket.on("callAnsweredResponded", (senderData) => {
        dispatch({
          type: TYPES.VIDEO_CALL_STATUS,
          payload: "connected",
        });
      });
      socket.on("endCall", (userId) => {
        dispatch({
          type: TYPES.VIDEO_CALL_STATUS,
          payload: null,
        });
        setTimeout(() => {
          navigate(`/chat-direct/${userId}`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }, 200);
      });
      socket.on("addNewConversationToClient", (data) => {
        dispatch({
          type: TYPES.CREATE_NEW_CONVERSATION,
          payload: data?.newConversation,
        });
      });
      socket.on("deleteConversationToClient", (data) => {
        dispatch({
          type: TYPES.DELETE_CONVERSATION,
          payload: data?.deleteConversation,
        });
        navigate("/");
      });
      socket.on("no_group_member_found", () => {
        Toaster({
          success: false,
          message: "No one is available for call",
        });
        dispatch({
          type: TYPES.GROUP_VIDEO_CALL_STATUS,
          payload: null,
        });
      });
      socket.on("sendCallToGroupMembers", (data) => {
        dispatch({
          type: TYPES.GROUP_VIDEO_CALL_SIGNALS,
          payload: data?.signalData,
        });
        dispatch({
          type: TYPES.GROUP_VIDEO_CALL_STATUS,
          payload: "ringing",
        });
      });
      socket.on("sendCallConfirmation", () => {
        dispatch({
          type: TYPES.GROUP_VIDEO_CALL_STATUS,
          payload: "connecting",
        });
      });
      socket.on("cancelGroupCall", () => {
        dispatch({
          type: TYPES.GROUP_VIDEO_CALL_STATUS,
          payload: null,
        });
      });
      socket.on("request_ansGroupCall_confirmed", () => {
        dispatch({
          type: TYPES.GROUP_VIDEO_CALL_STATUS,
          payload: "connected",
        });
      });
      socket.on("groupCall_answered", (data) => {
        dispatch({
          type: TYPES.GROUP_VIDEO_CALL_STATUS,
          payload: "connected",
        });
      });
      socket.on("notifyOnlineUserToClient", (data) => {
        dispatch({
          type: TYPES.UPDATE_ONLINE_STATUS,
          payload: data,
        });
      });
      socket.on("getOffOnlineUsers", (data) => {
        dispatch({
          type: TYPES.UPDATE_ONLINE_STATUS,
          payload: data,
        });
      });
      socket.on("connect_error", () => {
        setTimeout(() => {
          socket.on("connect", () => {
            dispatch(connectSocket());
            if (userData?.data?._id)
              socket.emit(`joinUser`, userData?.data?._id);
            socket.emit("joinApp", appSettings?._id);
            handleUserOnlineStatus(true, userData?.data?._id);
          });
        }, 1);
      });
      socket.on("disconnect", () => {
        socket.disconnect();
        dispatch(connectSocket());
      });
      socket.on("newstartcalltoclient", (data) => {
        if (data?.receiverData) {
          setShowCallModal(true);
          setCallType(data?.callType);
          setSenderData(data?.senderData);
          setConversationId(data?.conversationData?._id);
          handleCallNotification(data);
          dispatch({
            type: TYPES.VIDEO_CALL_STATUS,
            payload: "ringing",
          });
          dispatch({
            type: TYPES.SET_CALL_DATA,
            payload: {
              meetingId: data?.room?.roomId,
              name: data?.receiverData?.name,
              participantId: data?.receiverData?._id,
            },
          });
        } else {
          dispatch({
            type: TYPES.VIDEO_CALL_STATUS,
            payload: "connecting",
          });
        }
      });
      socket.on("answercalltoclient", (data) => {
        dispatch({
          type: TYPES.VIDEO_CALL_STATUS,
          payload: "connected",
        });
      });
      socket.on("start-meeting-to-client", (data) => {
        if (data) {
          setShowCallModal(true);
          setCallType(data?.callType);
          setSenderData(data?.senderData);
          setConversationId(data?.conversationData?._id);
          handleGroupCallNotification(data);
          dispatch({
            type: TYPES.GROUP_VIDEO_CALL_STATUS,
            payload: "ringing",
          });
          dispatch({
            type: TYPES.SET_GROUP_VIDEO_CALL_DATA,
            payload: {
              meetingId: data?.room?.roomId,
              name: userData?.data?.name,
              participantId: userData?.data?._id,
            },
          });
        } else {
          dispatch({
            type: TYPES.GROUP_VIDEO_CALL_STATUS,
            payload: "connecting",
          });
        }
      });
      socket.on("join-meeting-to-client", (data) => {
        if (!data) {
          dispatch({
            type: TYPES.GROUP_VIDEO_CALL_STATUS,
            payload: "connected",
          });
        }
      });
      socket.on("get-online-status", () => {
        socket.emit("get-online-status-back");
      });
      return () => {
        socket.off("start-meeting-to-client");
        socket.off("new-start-call_to_client");
        socket.off("addGroupMessageToClient");
        socket.off("receiveUpdatedGroupData");
        socket.off("createNewGroupToClient");
        socket.off("deleteGroupToClient");
        socket.off("updateDeleteMessageToClient");
        socket.off("addMessageToClient");
        socket.off("notificationResponse");
        socket.off("messageNotificationToClient");
        socket.off("requestRemovingChatToClient");
        socket.off("typingToClient");
        socket.off("typingInGroupToClient");
        socket.off("updateConversationToClient");
        socket.off("updateOneTooOneDeleteMsgToClient");
        socket.off("onlineUsersToClient");
        socket.off("broadcastOnlineUsersToClient");
        socket.off("clientChatAccepted");
        socket.off("user_not_found");
        socket.off("callUser");
        socket.off("callDeclined");
        socket.off("callCanceled");
        socket.off("callAnswered");
        socket.off("callAnsweredResponded");
        socket.off("endCall");
        socket.off("addNewConversationToClient");
        socket.off("deleteConversationToClient");
        socket.off("no_group_member_found");
        socket.off("sendCallToGroupMembers");
        socket.off("sendCallConfirmation");
        socket.off("cancelGroupCall");
        socket.off("request_ansGroupCall_confirmed");
        socket.off("groupCall_answered");
        socket.off("notifyOnlineUserToClient");
        socket.off("getOffOnlineUsers");
        socket.off("connect_error");
        socket.off("connect");
        socket.off("disconnect");
      };
    }
  }, [socket]);
  /////////////////

  const handleNetworkChange = () => {
    if (navigator.onLine) {
      if (userData?.data?._id && Object?.keys(socket)?.length > 0) {
        socket.connect();
        socket.on("connect", () => {
          dispatch(connectSocket());
          socket.emit(`joinUser`, userData?.data?._id);
          socket.emit("joinApp", appSettings?._id);
          handleUserOnlineStatus(true, userData?.data?._id);
        });
        return () => {
          socket.off("connect");
        };
      }
    } else {
      if (Object?.keys(socket)?.length > 0) {
        socket?.disconnect();
      }
    }
  };

  handleNetworkChange();

  window.addEventListener("online", handleNetworkChange);
  window.addEventListener("offline", handleNetworkChange);

  useEffect(() => {
    if (
      Object?.keys(socket)?.length > 0 &&
      userData?.data?._id &&
      Object?.keys(appSettings)?.length > 0
    ) {
      socket.emit(`joinUser`, userData?.data?._id);
      socket.emit("joinApp", appSettings?._id);
      handleUserOnlineStatus(true, userData?.data?._id);
    }
  }, [userData?.data, socket, appSettings]);
  useEffect(() => {
    if (!localStorage.getItem(`lang`)) {
      localStorage.setItem(`lang`, "en");
      i18n?.changeLanguage(localStorage.getItem(`lang`));
    }
  }, []);
  useEffect(() => {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", "dark");
    }
    if (Cookies.get(`accessToken`)) {
      dispatch(getAllFaq());
      dispatch(getAllBots());
      dispatch(getAllGroups());
      dispatch(connectSocket());
      dispatch(getAllWidgets());
      dispatch(getAllResponse());
      dispatch(getApplication());
      dispatch(getAllEngageEntry());
      dispatch(getAllDepartments());
      dispatch(getAllConversations());
      dispatch(applicationConversations());
      dispatch(getAllEmailTemplates());
    }

    return () => {};
  }, [Cookies.get(`accessToken`)]);
  // Getting user's coordinates (longiude & latitude) & media access (Camera & Mic)
  useEffect(() => {
    const success = (pos) => {
      const crd = pos.coords;
      setCoordinates({
        longitude: crd.longitude,
        latitude: crd.latitude,
      });
    };
    const error = (err) => {};
    navigator.geolocation.getCurrentPosition(success, error, {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    });
  }, []);
  // Getting Location with coordinates
  useEffect(() => {
    if (coordinates?.latitude !== "" && coordinates?.longitude !== "") {
      Location();
    }
  }, [coordinates]);
  useEffect(() => {
    dispatch(userSession(sessions));
  }, [userLocation]);
  // Setting App FavIcon And Title
  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = appSettings?.logo;
  }, [appSettings?.logo]);
  useEffect(() => {
    playChatSound = (conversationId) => {
      let currentChat = allConversations?.find((chat) => {
        return chat?._id === conversationId;
      });
      if (!currentChat?.is_mute?.includes(userData?.data?._id)) {
        send_message_sound();
      }
    };
  }, [allConversations, userData]);
  useEffect(() => {
    playGroupSound = (groupId) => {
      let currentGroup = allGroups?.find((group) => {
        return group?._id === groupId;
      });
      if (!currentGroup?.is_mute?.includes(userData?.data?._id)) {
        send_message_sound();
      }
    };
  }, [allGroups, userData]);
  // useEffect(() => {
  //   if (userData) {
  //     window.addEventListener("load", () => {
  //       setTimeout(() => {
  //         Notification.requestPermission()
  //           .then(function (permission) {
  //           })
  //           .catch((err) => {
  //           });
  //       }, 2 * 60 * 1000);
  //     });
  //   }
  // }, [userData]);

  const Location = async () => {
    let userAddress = await axios.get(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${coordinates?.latitude}&longitude=${coordinates.longitude}&localityLanguage=en`
    );
    setUserLocation(userAddress?.data);
  };
  // Getting user's current time
  var today = new Date();
  let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  // Getting user's DeviceOS
  let DeviceOs = "";
  if (navigator.userAgent.toLowerCase().includes("windows")) {
    DeviceOs = `Windows`;
  } else if (navigator.userAgent.toLowerCase().includes("macintosh")) {
    DeviceOs = `Mac`;
  } else if (navigator.userAgent.toLowerCase().includes("linux")) {
    DeviceOs = `Linux`;
  }
  let sessions = {
    browse_name: browserName,
    device_name: DeviceOs,
    user_location: userLocation,
    time: {
      day: days[today.getDay()],
      hour: today.getHours(),
      min: today.getMinutes(),
    },
  };

  useEffect(() => {
    if ((appSettings?.call_api_key, appSettings?.call_secret_key)) {
      getToken();
    }
  }, [appSettings]);

  const getToken = async () => {
    // Get video call token
    let res = await dispatch(
      getCallToken(appSettings?.call_api_key, appSettings?.call_secret_key)
    );
    sessionStorage.setItem("callToken", res.token);
  };

  const declineCall = () => {
    if (senderData?._id)
      if (videoCallStatus === "ringing") {
        socket.emit(`request_callDecline`, senderData);
      }
    if (groupVideoCallStatus === "ringing") {
      setShowCallModal(false);
    }
  };

  const answerCall = () => {
    setShowCallModal(false);
    if (senderData?._id)
      if (videoCallStatus === "ringing") {
        // navigate(`/chat-call/${senderData?._id}?T=${callType}`);
        sessionStorage.setItem("streamCheck", callType);
        navigate(`/call/${senderData?._id}`);
      } else {
        sessionStorage.setItem("streamCheck", callType);
        navigate(`/meeting/${conversationId}`);
      }
  };
  const handleUserOnlineStatus = (onlineStatus, userId) => {
    dispatch({
      type: TYPES.USER_ONLINE_STATUS,
      payload: onlineStatus,
    });
    socket.emit("notifyOnlineUser", {
      userId,
      onlineStatus,
      appId: appSettings?._id,
    });
  };
  const handleMessageNotification = (data) => {
    let payload = {
      notificationTitle: data?.senderId?.name,
      bodyMessage:
        data?.type === FILE_TYPES?.IMAGES || data?.type === FILE_TYPES?.FILES
          ? "Share file"
          : data?.type === FILE_TYPES?.VOICE
          ? "Voice message"
          : data?.text,
      icon: data?.senderId?.picture,
      notificationId: data?._id,
      link: data?.isCall
        ? `/call/${data?.senderId?._id}`
        : `/chat-direct/${data?.senderId?._id}`,
    };
    if (data?.isCall) {
      if (
        !document.hidden &&
        window.location.pathname === payload.link &&
        data?.isSidebarOpen
      ) {
      } else {
        PushNotification(payload, navigate);
      }
    } else {
      if (!document.hidden && window.location.pathname === payload.link) {
      } else {
        PushNotification(payload, navigate);
      }
    }
  };

  const handleCallNotification = (data) => {
    let payload = {
      notificationTitle: data?.senderData?.name,
      bodyMessage: data?.senderData?.name + " is calling",
      icon: data?.senderData?.picture,
      notificationId: data?.conversationData?._id,
      link: `/chat-direct/${data?.senderData?._id}`,
    };

    if (document.hidden) {
      PushNotification(payload, navigate);
    }
  };

  const handleGroupMessageNotification = (msg) => {
    let payload = {
      notificationTitle: `Group: ${msg?.group_title}`,
      bodyMessage: `${msg?.data?.senderId?.name}: ${
        msg?.type === FILE_TYPES?.DOCUMENT
          ? "Share file"
          : msg?.type === FILE_TYPES?.VOICE
          ? "Voice message"
          : msg?.data?.text
      }`,
      icon: msg?.data?.senderId?.picture,
      notificationId: msg?.data?._id,
      link: msg?.isCall
        ? `/meeting/${msg?.groupId}`
        : `/chat-group/${msg?.groupId}`,
    };
    if (msg?.isCall) {
      if (
        !document.hidden &&
        window.location.pathname === payload.link &&
        msg?.isSidebarOpen
      ) {
      } else {
        PushNotification(payload, navigate);
      }
    } else {
      if (!document.hidden && window.location.pathname === payload.link) {
      } else {
        PushNotification(payload, navigate);
      }
    }
  };

  const handleGroupCallNotification = (msg) => {
    let payload = {
      notificationTitle: `Group: ${msg?.conversationData?.group_title}`,
      bodyMessage: `${msg?.senderData?.name} is calling a meeting`,
      icon: msg?.senderData?.picture,
      notificationId: msg?.conversationData?._id,
      link: `/chat-group/${msg?.conversationData?._id}`,
    };

    if (document.hidden) {
      PushNotification(payload, navigate);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <title>
        {appSettings?.application_title
          ? appSettings?.application_title
          : "Zipp Chat"}
      </title>
      {content}
      {modal?.is_show && (
        <ConfirmationModal
          functionName={modal?.function}
          param={modal?.param}
        />
      )}
      <CallModal
        senderData={senderData}
        answerCall={answerCall}
        declineCall={declineCall}
        showCallModal={showCallModal}
        setShowCallModal={setShowCallModal}
      />
      <ShareFileModal />
      <ToastContainer />
    </React.Fragment>
  );
};

export default App;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/aria-props */
import React, { memo, useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import CardHeader from "../../assets/img/core/card-header.png";
import {
  createWidget,
  setWidgetId,
  editWidget,
} from "../../redux/actions/widgetAction";
import {
  Widget,
  MultipleSelect,
  ToastContainer as Toaster,
} from "../../components";
import { USER_ROLES } from "../../constant";

let REQUIRED_FILED = `Required field`;

const ChatWidget = ({ setModalShow, modalShow }) => {
  const [imgUrl, setImgUrl] = useState("");
  const [widgetLogo, setWidgetLogo] = useState(null);
  const [widgetLoading, setWidgetLoading] = useState(false);
  const [selectedOperators, setSelectedOperators] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  let selectedObj = {};
  const data = new FormData();
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.userReducer);
  const { allOperators } = useSelector((state) => state.operatorReducer);
  const { allDepartments } = useSelector((state) => state.departmentReducer);
  const { allWidgets, widgetObj, appWidgets } = useSelector(
    (state) => state.widgetReducer
  );

  let INITIAL_VALUE = {
    title: "",
    agreement: "",
    operators: [],
    language: "en",
    description: "",
    departments: [],
    widget_logo: null,
    is_chat_hide: false,
    is_show_phone: true,
    is_show_email: true,
    is_client_rate: true,
    is_phone_mandatory: true,
    is_email_mandatory: true,
    is_only_for_clients: false,
    is_show_register_option: true,
  };

  if (widgetObj?.purpose === "edit") {
    selectedObj = appWidgets?.find((x) => {
      return x?._id === widgetObj?.widgetId;
    });
  }
  useEffect(() => {
    if (widgetObj?.purpose === "edit") {
      setSelectedDepartments(selectedObj?.departments);
      setSelectedOperators(selectedObj?.operators);
      setImgUrl("");
      setWidgetLogo(selectedObj?.widget_logo ? selectedObj?.widget_logo : null);
    }
  }, [selectedObj]);

  let editedValues = {
    title: selectedObj?.title,
    agreement: selectedObj?.agreement,
    operators: selectedObj?.operators,
    language: selectedObj?.language,
    description: selectedObj?.description,
    departments: selectedObj?.departments,
    widget_logo: selectedObj?.widget_logo ? selectedObj?.widget_logo : null,
    is_chat_hide: selectedObj?.is_chat_hide,
    is_show_phone: selectedObj?.is_show_phone,
    is_show_email: selectedObj?.is_show_email,
    is_client_rate: selectedObj?.is_client_rate,
    is_phone_mandatory: selectedObj?.is_phone_mandatory,
    is_email_mandatory: selectedObj?.is_email_mandatory,
    is_only_for_clients: selectedObj?.is_only_for_clients,
    is_show_register_option: selectedObj?.is_show_register_option,
  };

  const validationSchema = yup.object({
    title: yup
      .string(REQUIRED_FILED)
      .min(3, "Title must be 3 characters long")
      .max(30, "Title must be less that 30 characters")
      .required(REQUIRED_FILED),
    description: yup
      .string(REQUIRED_FILED)
      .min(5, "Description must be 5 characters long")
      .required(REQUIRED_FILED),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: widgetObj?.purpose === "edit" ? editedValues : INITIAL_VALUE,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!widgetLoading) {
        setWidgetLoading(true);
        if (selectedDepartments?.length > 0 && availableOperators?.length < 1) {
          Toaster({
            success: false,
            message: "No operators are assigned to this department",
          });
          setWidgetLoading(false);
          return;
        }
        if (selectedDepartments?.length > 0) {
          values.departments = selectedDepartments;
        } else {
          Toaster({
            success: false,
            message: "Please select department",
          });
          setWidgetLoading(false);
          return;
        }
        if (selectedOperators?.length > 0) {
          values.operators = selectedOperators;
        } else {
          Toaster({
            success: false,
            message: "Please select operator",
          });
          setWidgetLoading(false);
          return;
        }
        values.added_by = userData?.data?._id;
        if (widgetLogo) {
          values.widget_logo = widgetLogo;
        } else {
          delete values.widget_logo;
        }
        values.departments = JSON.stringify(values.departments);
        values.operators = JSON.stringify(values.operators);
        for (const key in values) {
          data.append(key, values[key]);
        }

        let res = {};
        if (widgetObj?.purpose === "edit") {
          res = await dispatch(editWidget(selectedObj?._id, data));
        } else {
          res = await dispatch(createWidget(data));
        }

        if (res?.success) {
          resetForm();
          setImgUrl("");
          setModalShow(false);
          setWidgetLogo(null);
          setSelectedOperators([]);
          setSelectedDepartments([]);
        }
        Toaster(res);
        setWidgetLoading(false);
      }
    },
  });

  const selectWidgetLogo = (e) => {
    setWidgetLogo(e.target.files[0]);
    setImgUrl(window.URL.createObjectURL(e.target.files[0]));
  };

  const addNewWidget = () => {
    dispatch(setWidgetId("123", "addNew"));
    setImgUrl("");
    setWidgetLogo(null);
  };

  const handleRequiredCheckbox = () => {
    Toaster({
      success: false,
      message: "This option is required for registeration",
    });
  };

  let filteredOperators = [];
  filteredOperators = allOperators?.filter((operator) => {
    return operator?.user_role !== USER_ROLES?.EDITOR;
  });

  const getOperatorsForSelectedDepartments = (
    selectedDepartments,
    Operators
  ) => {
    let selectedOperators = [];

    for (let operator of Operators) {
      for (let department of operator?.department) {
        if (selectedDepartments?.includes(department)) {
          selectedOperators?.push(operator);
          break;
        }
      }
    }

    return selectedOperators;
  };

  let availableOperators = getOperatorsForSelectedDepartments(
    selectedDepartments,
    filteredOperators
  );

  return (
    <Modal
      centered
      size="md"
      show={modalShow}
      onHide={() => setModalShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close btn-close-arrow"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalShow(false)}
            />
          </div>
          <div className="modal-body py-0">
            <div className="modal-py">
              <div className="mb-6">
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-bs-toggle="pill"
                      href="#create-chat-widget"
                      role="tab"
                      aria-controls="create-chat-widget"
                      onClick={addNewWidget}
                    >
                      New Chat Widget
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="pill"
                      href="#existing-chat-widget"
                      role="tab"
                      aria-controls="existing-chat-widget"
                    >
                      Current Widgets
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content" role="tablist">
                <div
                  className="tab-pane fade show active"
                  id="create-chat-widget"
                  role="tabpanel"
                >
                  <div className="card border-0">
                    <div className="profile">
                      <div className="profile-img text-primary rounded-top">
                        <img
                          src={CardHeader}
                          style={{ width: "100%" }}
                          alt="card"
                        />
                      </div>
                      <div className="profile-body p-0">
                        <div className="avatar avatar-lg user_profile_pic_div">
                          {widgetObj?.purpose === "edit" ? (
                            selectedObj?.widget_logo || imgUrl !== "" ? (
                              <img
                                className="avatar-img round_avatar"
                                src={
                                  imgUrl !== ""
                                    ? imgUrl
                                    : selectedObj?.widget_logo
                                }
                                alt="#"
                              />
                            ) : (
                              <span className="avatar-text">
                                {selectedObj?.title?.charAt(0).toUpperCase()}
                              </span>
                            )
                          ) : (
                            <React.Fragment>
                              {imgUrl ? (
                                <img
                                  className="avatar-img round_avatar"
                                  src={imgUrl}
                                  alt="#"
                                />
                              ) : (
                                <span className="avatar-text bg-primary">
                                  <i className="fal fa-image"></i>
                                </span>
                              )}
                            </React.Fragment>
                          )}
                          <div className="badge badge-lg badge-circle bg-primary border-outline position-absolute bottom-0 end-0">
                            <i className="fal fa-plus"></i>
                          </div>
                          <input
                            id="widget-image"
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={(e) => selectWidgetLogo(e)}
                          />
                          <label
                            className="d-grid"
                            htmlFor="widget-image"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <form autoComplete="off">
                        <div className="row gy-6">
                          <div className="col-12">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Enter a chat name"
                                name="title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                              />
                              <label htmlFor="floatingInput">Enter Title</label>
                              <small
                                style={{ color: "red", marginLeft: "10px" }}
                              >
                                {formik.touched.title && formik.errors.title}
                              </small>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-floating">
                              <textarea
                                className="form-control"
                                placeholder="Description"
                                id="floatingTextarea"
                                rows="8"
                                data-autosize="true"
                                style={{ minHeight: "100px" }}
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                              ></textarea>
                              <label htmlFor="floatingTextarea">
                                Enter a Description
                              </label>
                              <small
                                style={{ color: "red", marginLeft: "10px" }}
                              >
                                {formik.touched.description &&
                                  formik.errors.description}
                              </small>
                            </div>
                          </div>
                          <div className="col-12">
                            <MultipleSelect
                              count
                              label="Select Department"
                              allItems={allDepartments}
                              allOperators={filteredOperators}
                              selectedItems={selectedDepartments}
                              setSelectedItems={setSelectedDepartments}
                            />
                          </div>
                          {selectedDepartments?.length > 0 &&
                            availableOperators?.length > 0 && (
                              <div className="col-12">
                                <MultipleSelect
                                  label="Select Operator"
                                  allItems={availableOperators}
                                  selectedItems={selectedOperators}
                                  setSelectedItems={setSelectedOperators}
                                />
                              </div>
                            )}
                          <div className="col-12">
                            <div className="form-floating">
                              <select
                                className="form-select"
                                id="language"
                                name="language"
                                value={formik.values.language}
                                onChange={formik.handleChange}
                              >
                                <option value="en" defaultValue>
                                  EN
                                </option>
                                <option value="de">DE</option>
                                <option value="es">ES</option>
                              </select>
                              <label htmlFor="language">Language</label>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-floating">
                              <textarea
                                className="form-control"
                                placeholder="EU-DSGV or Agreement"
                                id="settings-dsgv"
                                data-autosize="true"
                                style={{ minHeight: "120px" }}
                                name="agreement"
                                value={formik.values.agreement}
                                onChange={formik.handleChange}
                              ></textarea>
                              <label htmlFor="settings-dsgv">
                                EU-DSGV or Agreement
                              </label>
                              <small
                                style={{ color: "red", marginLeft: "10px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="mt-8">
                    <div className="d-flex align-items-center mb-4 px-6">
                      <small className="text-muted me-auto">Form Options</small>
                    </div>

                    <div className="card border-0">
                      <div className="card-body">
                        <div className="row gx-5">
                          <div className="col-auto">
                            <div className="btn btn-sm btn-icon btn-dark">
                              <i className="fal fa-phone"></i>
                            </div>
                          </div>
                          <div className="col">
                            <h5>Phone</h5>
                            <p>Is the phone field mandatory?</p>
                          </div>
                          <div className="col-auto align-self-center">
                            <div className="form-check form-switch ps-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="new-chat-form-1"
                                name="is_phone_mandatory"
                                onChange={formik.handleChange}
                                value={formik.values.is_phone_mandatory}
                                checked={formik.values.is_phone_mandatory}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="new-chat-form-1"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card border-0">
                      <div className="card-body">
                        <div className="row gx-5">
                          <div className="col-auto">
                            <div className="btn btn-sm btn-icon btn-dark">
                              <i className="fal fa-eye-slash"></i>
                            </div>
                          </div>
                          <div className="col">
                            <h5>Phone Field</h5>
                            <p>Should we show the phone field?</p>
                          </div>
                          <div className="col-auto align-self-center">
                            <div className="form-check form-switch ps-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="new-chat-form-3"
                                name="is_show_phone"
                                onChange={formik.handleChange}
                                value={formik.values.is_show_phone}
                                checked={formik.values.is_show_phone}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="new-chat-form-3"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card border-0">
                      <div className="card-body">
                        <div className="row gx-5">
                          <div className="col-auto">
                            <div className="btn btn-sm btn-icon btn-dark">
                              <i className="fal fa-envelope"></i>
                            </div>
                          </div>
                          <div className="col">
                            <h5>Email</h5>
                            <p>Is the email field mandatory?</p>
                          </div>
                          <div className="col-auto align-self-center">
                            <div className="form-check form-switch ps-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="new-chat-form-2"
                                name="is_email_mandatory"
                                onChange={formik.handleChange}
                                value={formik.values.is_email_mandatory}
                                // checked={formik.values.is_email_mandatory}
                                checked={true}
                                onClick={handleRequiredCheckbox}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="new-chat-form-2"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card border-0">
                      <div className="card-body">
                        <div className="row gx-5">
                          <div className="col-auto">
                            <div className="btn btn-sm btn-icon btn-dark">
                              <i className="fal fa-eye-slash"></i>
                            </div>
                          </div>
                          <div className="col">
                            <h5>Email Field</h5>
                            <p>Should we show the email field?</p>
                          </div>
                          <div className="col-auto align-self-center">
                            <div className="form-check form-switch ps-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="new-chat-form-3"
                                name="is_show_email"
                                onChange={formik.handleChange}
                                value={formik.values.is_show_email}
                                // checked={formik.values.is_show_email}
                                checked={true}
                                onClick={handleRequiredCheckbox}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="new-chat-form-3"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-8">
                    <div className="d-flex align-items-center mb-4 px-6">
                      <small className="text-muted me-auto">Options</small>
                    </div>

                    <div className="card border-0">
                      <div className="card-body">
                        <div className="row gx-5">
                          <div className="col-auto">
                            <div className="btn btn-sm btn-icon btn-dark">
                              <i className="fal fa-user"></i>
                            </div>
                          </div>
                          <div className="col">
                            <h5>Only for Clients</h5>
                            <p>Can only be viewed by clients?</p>
                          </div>
                          <div className="col-auto align-self-center">
                            <div className="form-check form-switch ps-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="new-chat-options-1"
                                name="is_only_for_clients"
                                onChange={formik.handleChange}
                                value={formik.values.is_only_for_clients}
                                // checked={formik.values.is_only_for_clients}
                                checked={false}
                                onClick={() =>
                                  Toaster({
                                    success: false,
                                    message: "Under Construction",
                                  })
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="new-chat-options-1"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card border-0">
                      <div className="card-body">
                        <div className="row gx-5">
                          <div className="col-auto">
                            <div className="btn btn-sm btn-icon btn-dark">
                              <i className="fal fa-user"></i>
                            </div>
                          </div>
                          <div className="col">
                            <h5>Register</h5>
                            <p>Show Register Option?</p>
                          </div>
                          <div className="col-auto align-self-center">
                            <div className="form-check form-switch ps-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="new-chat-options-4"
                                name="is_show_register_option"
                                onChange={formik.handleChange}
                                value={formik.values.is_show_register_option}
                                checked={formik.values.is_show_register_option}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="new-chat-options-4"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card border-0">
                      <div className="card-body">
                        <div className="row gx-5">
                          <div className="col-auto">
                            <div className="btn btn-sm btn-icon btn-dark">
                              <i className="fal fa-star"></i>
                            </div>
                          </div>
                          <div className="col">
                            <h5>Rating</h5>
                            <p>Clients can rate operators?</p>
                          </div>
                          <div className="col-auto align-self-center">
                            <div className="form-check form-switch ps-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="new-chat-options-2"
                                name="is_client_rate"
                                onChange={formik.handleChange}
                                value={formik.values.is_client_rate}
                                checked={formik.values.is_client_rate}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="new-chat-options-2"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card border-0">
                      <div className="card-body">
                        <div className="row gx-5">
                          <div className="col-auto">
                            <div className="btn btn-sm btn-icon btn-dark">
                              <i className="fal fa-eye-slash"></i>
                            </div>
                          </div>
                          <div className="col">
                            <h5>Hide</h5>
                            <p>Hide chat when no operator is online?</p>
                          </div>
                          <div className="col-auto align-self-center">
                            <div className="form-check form-switch ps-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="new-chat-options-3"
                                name="is_chat_hide"
                                onChange={formik.handleChange}
                                value={formik.values.is_chat_hide}
                                // checked={formik.values.is_chat_hide}
                                checked={false}
                                onClick={() =>
                                  Toaster({
                                    success: false,
                                    message: "Under Construction",
                                  })
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="new-chat-options-3"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="existing-chat-widget"
                  role="tabpanel"
                >
                  <div className="card-list">
                    {appWidgets?.length > 0 ? (
                      appWidgets?.map((item, index) => (
                        <Widget
                          key={index}
                          item={item}
                          setModalShow={setModalShow}
                        />
                      ))
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                        }}
                      >
                        No Widgets Found
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <hr className="hr-bold modal-gx-n my-0" />

            <div className="modal-py">
              <button
                type="submit"
                className="btn btn-lg btn-primary w-100 d-flex align-items-center"
                style={{ height: "56px" }}
              >
                {widgetLoading ? (
                  <div
                    className="spinner-grow text-light"
                    role="status"
                    style={{
                      backgroundColor: "white",
                      width: "15px",
                      height: "15px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <React.Fragment>
                    {widgetObj?.purpose === "edit" ? "Update" : "Save"}
                    <span className="icon ms-auto">
                      <i className="fal fa-chevron-right"></i>
                    </span>
                  </React.Fragment>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default memo(ChatWidget);
